<template>
  <div>
    <!--              table-->
    <b-row>
      <b-col class="">
        <b-overlay :show="tableLoading" rounded="sm">
          <b-table
            ref="refUserListTable"
            :current-page="currentPage"
            :fields="fields"
            :items="getSalariedStaff"
            class="position-relative mobile_table_css"
            empty-text="No matching records found"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            hover
            primary-key="id"
            responsive
            show-empty
          >
            <template #cell(name)="data, index">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <b-avatar
                    :src="
                      require('@/assets/images/portrait/small/avatar-s-5.jpg')
                    "
                    class="pull-up"
                  />
                </li>
                <li class="list-inline-item">
                  {{ data.item.name }}
                  <b-badge
                    v-if="data.item.number === '10'"
                    variant="light-primary"
                    class="font-weight-bold"
                  >
                    L
                  </b-badge>
                </li>
              </ul>
            </template>
            <template #cell(is_archive)="data">
              <b-badge
                variant="light-primary"
                v-if="data.item.is_archive === 'Active'"
                >{{ data.item.is_archive }}</b-badge
              >
              <b-badge variant="light-danger" v-else>{{
                data.item.is_archive
              }}</b-badge>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
    </b-row>

    <div class="mx-1 mb-2">
      <b-row>
        <!--        <b-col cols="12" v-if="noDataTable === 0" class="text-center">-->
        <!--          <span >No data for preview</span>-->
        <!--        </b-col>-->

        <!-- Pagination -->

        <b-col
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
          cols="12"
          sm="6"
        >
          <span class="text-muted" v-if="pagination.totalRows !== 0"
            >Showing {{ pagination.from }} to {{ pagination.to }} of
            {{ pagination.totalRows }} entries</span
          >
        </b-col>

        <!-- Pagination -->

        <b-col
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
          cols="12"
          sm="6"
        >
          <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0"
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <!--                pagination-->
    <!--    <div class="mx-1 mb-2">-->
    <!--      <b-row>-->

    <!--        <b-col-->
    <!--            class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
    <!--            cols="12"-->
    <!--            sm="6"-->
    <!--        >-->
    <!--          <span class="text-muted">Showing 1 to 10 of 187 entries</span>-->
    <!--        </b-col>-->
    <!--        &lt;!&ndash; Pagination &ndash;&gt;-->
    <!--        <b-col-->
    <!--            class="d-flex align-items-center justify-content-center justify-content-sm-end"-->
    <!--            cols="12"-->
    <!--            sm="6"-->
    <!--        >-->

    <!--          <b-pagination-->
    <!--              v-model="currentPage"-->
    <!--              :per-page="10"-->
    <!--              :total-rows="187"-->
    <!--              class="mb-0 mt-1 mt-sm-0 "-->
    <!--              first-number-->
    <!--              last-number-->
    <!--              next-class="next-item"-->
    <!--              prev-class="prev-item"-->
    <!--          >-->
    <!--            <template #prev-text>-->
    <!--              <feather-icon-->
    <!--                  icon="ChevronLeftIcon"-->
    <!--                  size="18"-->
    <!--              />-->
    <!--            </template>-->
    <!--            <template #next-text>-->
    <!--              <feather-icon-->
    <!--                  icon="ChevronRightIcon"-->
    <!--                  size="18"-->
    <!--              />-->
    <!--            </template>-->
    <!--          </b-pagination>-->

    <!--        </b-col>-->

    <!--      </b-row>-->
    <!--    </div>-->
  </div>
</template>

<script>
import AddProgrammeSideBar from "@/views/programmes/sidebars/AddProgrammeSideBar";
import connectionRequests from "@/apis/modules/connectionRequests";

import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  BOverlay,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: "staff",
  components: {
    AddProgrammeSideBar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BOverlay,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },

  data: function () {
    return {
      tableLoading: false,
      currentPage: 1,
      sortBy: "",
      sortDesc: true,
      sortDirection: "asc",
      pagination: {
        perPage: "",
        pageOptions: "",
        totalRows: "",
        from: "",
        to: "",
      },
      fields: [
        {
          key: "name",
          sortable: true,
        },
        {
          key: "role",
          sortable: true,
        },

        {
          key: "telephone",
        },
        {
          key: "email",
        },
        {
          key: "total_hr_worked",
          sortable: true,
        },
        {
          key: "is_archive",
          label: "Status",
        },
        // {
        //   key: 'total_earned',
        //   sortable: true
        // }
      ],
      // items: [
      //   {
      //     'name': 'Sandoval4545 Kim',
      //     'role': 'QUIZKA',
      //     'number': '15',
      //     'telephone': '+1 (876) 511-2396',
      //     'email': 'sandovalkim@quizka.com',
      //     'total_hr_worked': '$3,428.56',
      //     'total_earned': '$1,428.56'
      //   },
      //   {
      //     'name': 'Mildred Mcleod',
      //     'role': 'ANDRYX',
      //     'number': '15',
      //     'telephone': '+1 (894) 516-2935',
      //     'email': 'mildredmcleod@andryx.com',
      //     'total_hr_worked': '$3,428.56',
      //     'total_earned': '$3,428.56'
      //   },
      //   {
      //     'name': 'Scott Garner',
      //     'role': 'INTRADISK',
      //     'number': '10',
      //     'telephone': '+1 (820) 556-2808',
      //     'email': 'scottgarner@intradisk.com',
      //     'total_hr_worked': '$3,428.56',
      //     'total_earned': '$1,428.56'
      //   },
      //   {
      //     'name': 'Minerva Herman',
      //     'role': 'POLARIUM',
      //     'number': '15',
      //     'telephone': '+1 (995) 486-2958',
      //     'email': 'minervaherman@polarium.com',
      //     'total_hr_worked': '$3,428.56',
      //     'total_earned': '$3,428.56'
      //   },
      //   {
      //     'name': 'Faith Hutchinson',
      //     'role': 'INVENTURE',
      //     'number': '15',
      //     'telephone': '+1 (849) 586-2381',
      //     'email': 'faithhutchinson@inventure.com',
      //     'total_hr_worked': '$3,428.56',
      //     'total_earned': '$428.56'
      //   }, {
      //     'name': 'Sandoval45 Kim',
      //     'role': 'QUIZKA',
      //     'number': '10',
      //     'telephone': '+1 (876) 511-2396',
      //     'email': 'sandovalkim@quizka.com',
      //     'total_hr_worked': '$3,428.56',
      //     'total_earned': '$3,428.56'
      //   },
      //   {
      //     'name': 'Mildred Mcleod',
      //     'role': 'ANDRYX',
      //     'number': '15',
      //     'telephone': '+1 (894) 516-2935',
      //     'email': 'mildredmcleod@andryx.com',
      //     'total_hr_worked': '$3,428.56',
      //     'total_earned': '$3,428.56'
      //   },
      //   {
      //     'name': 'Scott Garner',
      //     'role': 'INTRADISK',
      //     'number': '15',
      //     'telephone': '+1 (820) 556-2808',
      //     'email': 'scottgarner@intradisk.com',
      //     'total_hr_worked': '$28.56',
      //     'total_earned': '$428.56'
      //   },
      //   {
      //     'name': 'Minerva Herman',
      //     'role': 'POLARIUM',
      //     'number': '10',
      //     'telephone': '+1 (995) 486-2958',
      //     'email': 'minervaherman@polarium.com',
      //     'total_hr_worked': '$3,428.56',
      //     'total_earned': '$3,428.56'
      //   },
      //   {
      //     'name': 'Faith Hutchinson',
      //     'role': 'INVENTURE',
      //     'number': '15',
      //     'telephone': '+1 (849) 586-2381',
      //     'email': 'faithhutchinson@inventure.com',
      //     'total_hr_worked': '$3,428.56',
      //     'total_earned': '428.56'
      //   }
      // ]
    };
  },

  mounted() {},

  methods: {
    async getSalariedStaff() {
      try {
        this.tableLoading = true;
        const Response = await connectionRequests.getSalariedStaff(
          this.$route.params.id,
          this.currentPage,
          10
        );
        console.log(Response)
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          name: x.first_name + " " + x.last_name,
          role: x.job_roles[0].name,
          telephone: x.phone_number ? x.phone_number : "-",
          email: x.email,
          // total_hr_worked: '-'
          is_archive: x.pivot.is_archive=== 0 ? "Active" : "Archived",
        }));
        const paginationResponse = Response.data.meta;
        this.currentPage = paginationResponse.current_page;
        this.pagination.totalRows = paginationResponse.total;
        this.pagination.perPage = paginationResponse.per_page;
        this.pagination.from = paginationResponse.from;
        this.pagination.to = paginationResponse.to;
        this.tableLoading = false;
        return dataArray;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
  },
};
</script>

<style scoped>
</style>
