<template>
  <div>
    <div class="d-flex flex-row mb-2 flex-wrap pr-view">
      <b-button
          @click="$router.back()"
          class="btn-icon bg-white shadow-sm font-weight-bold mr-2 align-self-baseline"
          variant="white">
        <feather-icon icon="ArrowLeftIcon" size="20"/>
      </b-button>
      <div>
        <h2 class="font-weight-bold">{{networkPracticeDetails.name}}</h2>
        <span class="mr-1">
                    <feather-icon icon="MapPinIcon"/>
                   {{networkPracticeDetails.address}}
        </span>
        <span class="mr-1">
                    <feather-icon icon="PhoneIcon"/>
                   {{networkPracticeDetails.phone_number}}
        </span>
        <span class="mr-1">
                    <feather-icon icon="MailIcon"/>
                  {{networkPracticeDetails.email}}
        </span>
        <span class="mr-1">
                    <feather-icon icon="CreditCardIcon"/>
                   £{{networkPracticeDetails.service_charge}}/hour
        </span>

      </div>
      <!-- <div class="ml-lg-auto">
        <b-button
            class="btn-icon bg-white shadow-sm font-weight-bold mr-1"
            variant="white">
          <feather-icon icon="EditIcon" size="20"/>
        </b-button>
        <b-button
            class="btn-icon bg-white shadow-sm font-weight-bold"
            variant="white"
            @click="confirmButtonColor"
            >
          <feather-icon icon="TrashIcon" size="20"/>
        </b-button>
      </div> -->
    </div>
    <b-card class="mb-0" no-body>
      <div class="m-2">

        <b-tabs lazy>
          <b-tab title="Salaried Staff">
            <staff></staff>
          </b-tab>
          <b-tab title="Locums">
            <Locums></Locums>
          </b-tab>
          <b-tab title="Payments">
            <Payments></Payments>
          </b-tab>
        </b-tabs>

      </div>
    </b-card>

  </div>
</template>

<script>
import Staff from '@/views/networks/views/staff'
import Payments from '@/views/locations/list/view/payments'
import Locums from '@/views/networks/views/locums'
import connectionRequests from "@/apis/modules/connectionRequests";

import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'program-list',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BTabs,
    BTab,
    Staff,
    Payments,
    Locums,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  methods:{
    async getNetworkPracticeDetails() {
      try{
         const Response = await connectionRequests.getDetailsOfNetworkPractice(this.$route.params.id)
        this.networkPracticeDetails = Response.data.data
      }catch(error) {
        this.convertAndNotifyError(error);
      }
    },

     confirmButtonColor() {
      this.$swal({
        title: 'Are you sure you want to delete this location?',
        text: "All data related to this location will be removed. This action cannot be reverted.",
        imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Your imaginary file is safe :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },

  mounted () {
    this.getNetworkPracticeDetails()
  },

  data: function () {
    return {
      networkPracticeDetails: {}
    }
  }
}
</script>

<style scoped>

</style>
