<template>
  <div>
    <!--              table-->
    <b-row>
      <b-col class="">
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
          <b-table
              ref="refUserListTable"
              :current-page="currentPage"
              :fields="fields"
              :items="getNetworkPracticeLocums" class="position-relative mobile_table_css"
              empty-text="No matching records found"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              hover
              primary-key="id"
              responsive
              show-empty
          >
            <template #cell(name)="data,index">

              <ul class="list-inline">
                <li class="list-inline-item">
                  <b-avatar
                      :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')"
                      class="pull-up"
                  />
                </li>
                <li class="list-inline-item">
                  {{ data.item.name }}
                  <b-badge v-if="data.item.number ==='10'" variant="light-primary" class="font-weight-bold">
                    L
                  </b-badge>
                </li>
              </ul>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
    </b-row>

    <div class="mx-1 mb-2">
      <b-row>

        <!-- Pagination -->

        <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
          <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.totalRows }} entries</span>
        </b-col>

        <!-- Pagination -->

        <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

          <b-pagination
              v-model="currentPage"
              :per-page="pagination.perPage"
              :total-rows="pagination.totalRows"
              class="mb-0 mt-1 mt-sm-0 "
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item">

            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>

          </b-pagination>

        </b-col>


      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import connectionRequests from '@/apis/modules/connectionRequests'

export default {
  name: 'locums',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BOverlay,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },

  data: function () {
    return {
      tableLoading: false,
      currentPage: 1,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'asc',
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'role',
          sortable: true
        },

        {
          key: 'telephone'
        },
        {
          key: 'email'
        },
        {
          key: 'total_hr_worked',
          sortable: true
        }
      ]
    }
  },

  mounted () {
    this.getNetworkPracticeLocums()
  },

  methods: {
    async getNetworkPracticeLocums () {
      try {
        this.tableLoading = true
        const Response = await connectionRequests.getNetworkPracticeLocums(this.$route.params.id, this.currentPage, 10)
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          name: x.locum.user.first_name + ' ' + x.locum.user.last_name,
          role: x.locum.user.job_roles[0].name,
          telephone: x.locum.user.phone_number ? x.locum.user.phone_number : '-',
          email: x.locum.user.email,
          total_hr_worked: '-'
        }))
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false
        return dataArray
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    }
  }

}
</script>